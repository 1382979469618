
// import React from "react";
// import PageHead from "../Head";
// import Context from "@/context/Context";
// import HeaderDashboard from "@/components/Header/HeaderDashboard";
// import PopupMobileMenu from "@/components/Header/PopupMobileMenu";
// import LeftpanelDashboard from "@/components/Common/LeftpanelDashboard";
// import Dashboard from "@/components/Dashboard/Dashboard";
// import Modal from "@/components/Common/Modal";

// const DashboardPage = () => {
//   return (
//     <>
//       <PageHead title="Home" />

//       <main className="page-wrapper rbt-dashboard-page">
//         <Context>
//           <div className="rbt-panel-wrapper">
//             <HeaderDashboard display="d-none" />
//             <PopupMobileMenu />
//             <LeftpanelDashboard />
//             <Modal />

//             <Dashboard />
//           </div>
//         </Context>
//       </main>
//     </>
//   );
// };

// export default DashboardPage;




import Header from "@/components/Header/Header";
import HeaderTop from "@/components/Header/HeaderTop/Header-Top";
import PopupMobileMenu from "@/components/Header/PopupMobileMenu";
import Home from "@/components/Home/Home";
import Service from "@/components/Service/Service";
import Context from "@/context/Context";
import Separator from "../separator";
import Timeline from "@/components/Timeline/Timeline";
import Split from "@/components/Split/Split";
import Pricing from "@/components/Pricing/Pricing";
import Accordion from "@/components/Accordion/Accordion";
import Brands from "@/components/Brands/Brands";
import CallToAction from "@/components/CallToAction/CallToAction";
import Footer from "@/components/Footer/Footer";
import Copyright from "@/components/Footer/Copyright";
import PageHead from "../Head";
import { useRouter } from "next/router";
import { useEffect } from "react";

const HomePage = () => {
  const router = useRouter();
  // useEffect(() => {
  //   router.push('/dashboard');
  // }, []);
  return (
    <>
      <PageHead title="Home" />

      <main className="page-wrapper">
        <Context>
          {/* <HeaderTop /> */}
          <Header
            headerTransparent="header-not-transparent"
            headerSticky="header-sticky"
            btnClass="btn-small round"
          />
          <PopupMobileMenu />

          <Home />
          <Service />
          <Separator top={true} />
          {/* <Timeline />
          <Separator top={false} />
          <Split />
          <Separator top={true} /> */}
          <Accordion isHead={true} />
          <Separator top={false} />
          <Pricing />
          <Separator top={true} />
          <Brands />
          {/* <Separator top={true} /> */}
          {/* <CallToAction /> */}

          <Footer />
          <Copyright />
        </Context>
      </main>
    </>
  );
};

export default HomePage;

import React, { useEffect } from "react";

import sal from "sal.js";

import ServiceItem from "./ServiceItem";
import ServiceData from "../../data/home.json";
import Link from "next/link";

import { useUserContext } from "@/context/UserContext";
const Service = () => {
  const {userLoggedIn} = useUserContext();
  useEffect(() => {
    sal();

    const cards = document.querySelectorAll(".bg-flashlight");

    cards.forEach((bgflashlight) => {
      bgflashlight.onmousemove = function (e) {
        let x = e.pageX - bgflashlight.offsetLeft;
        let y = e.pageY - bgflashlight.offsetTop;

        bgflashlight.style.setProperty("--x", x + "px");
        bgflashlight.style.setProperty("--y", y + "px");
      };
    });
  }, []);
  return (
    <>
      <div className="jureeltd-service-area jureeltd-section-gap">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="section-title text-center"
                data-sal="slide-up"
                data-sal-duration="700"
                data-sal-delay="100"
              >
                <h4 className="subtitle">
                  <span className="theme-gradient">What does Juree offer?</span>
                </h4>
                <h2 className="title w-600 mb--20">
                Your Essential Legal Tools
                </h2>
                <p className="description b1">
                Research, Apply, and Connect - All in One Place
                </p>
              </div>
            </div>
          </div>

          <div className="row row--15 service-wrapper">
            <ServiceItem ServiceData={ServiceData} />
          </div>
          <div className="button-group mt--50 text-center">
                  <Link
                    className="btn-default bg-light-gradient btn-large"
                    href={userLoggedIn ? "/dashboard" : "/SignUp"}
                  >
                    <div className="has-bg-light"></div>
                    <span>{userLoggedIn ? "Go to Dashboard" : "Start using now!"}</span>
                  </Link>
                </div>
        </div>
      </div>
    </>
  );
};

export default Service;

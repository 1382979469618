import Image from "next/image";

import durhamUniversity from "../../public/images/universities-large/durham-university.png";
import cambridgeUniversity from "../../public/images/universities-large/cambridge-university.png";
import ucl from "../../public/images/universities-large/ucl.png";
import warwickUniversity from "../../public/images/universities-large/warwick-university.gif";
import kingsCollegeLondon from "../../public/images/universities-large/kings-college-london.png";
import coventryUniversity from "../../public/images/universities-large/coventry-university.png";


const Brands = () => {
  return (
    <>
      <div className="jureeltd-brand-area jureeltd-section-gap">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="section-title text-center sal-animate"
                data-sal="slide-up"
                data-sal-duration="700"
                data-sal-delay="100"
              >
                <h4 className="subtitle ">
                  <span className="theme-gradient">Trusted by Students From</span>
                </h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 mt--10">
              <ul className="brand-list brand-style-2">
                {/* <li>
                  <a href="#">
                    <Image
                      src={ucl}
                      width={180}
                      height={130}
                      alt="Brand Image"
                    />
                  </a>
                </li> */}
                <li style={{ maxHeight: 'none', height: 'auto' }}>
                  <a style={{ maxHeight: 'none', height: 'auto' }}>
                    <Image
                      src={warwickUniversity}
                      width={100}
                      height="auto"
                      alt="Warwick University Logo"
                      style={{ width: '100px', height: 'auto', maxHeight: 'none' }}
                      priority
                    />
                  </a>
                </li>
                <li style={{ maxHeight: 'none', height: 'auto' }}>
                  <a style={{ maxHeight: 'none', height: 'auto' }}>
                    <Image
                      src={durhamUniversity}
                      width={160}
                      height={0}
                      alt="Durham University Logo"
                      style={{ width: '160px', height: 'auto', maxHeight: 'none' }}
                      priority
                    />
                  </a>
                </li>
                <li style={{ maxHeight: 'none', height: 'auto' }}>
                  <a style={{ maxHeight: 'none', height: 'auto' }}>
                    <Image
                      src={cambridgeUniversity}
                      width={160}
                      height="auto"
                      alt="Cambridge University Logo"
                      style={{ width: '160px', height: 'auto', maxHeight: 'none' }}
                      priority
                    />
                  </a>
                </li>
                <li style={{ maxHeight: 'none', height: 'auto' }}>
                  <a style={{ maxHeight: 'none', height: 'auto' }}>
                    <Image
                      src={kingsCollegeLondon}
                      width={80}
                      height="auto"
                      alt="Kings College London Logo"
                      style={{ width: '80px', height: 'auto', maxHeight: 'none' }}
                      priority
                    />
                  </a>
                </li>
                <li style={{ maxHeight: 'none', height: 'auto' }}>
                  <a style={{ maxHeight: 'none', height: 'auto' }}>
                    <Image
                      src={coventryUniversity}
                      width={160}
                      height="auto"
                      alt="Coventry University Logo"
                      style={{ width: '160px', height: 'auto', maxHeight: 'none' }}
                      priority
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Brands;
